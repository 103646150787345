import * as React from 'react';
import { useState, Fragment, useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import { useMutation, useQuery } from "react-query";
import apiClient from '../../../request/http-common';
import { CardActions, Container } from '@mui/material';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import { useParams } from 'react-router-dom';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import UserPlates from './UserPlates';
import UserFundings from './UserFundings';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import Button from '@mui/material/Button';
import UserWithdrawals from './UserWithdrawals';
import UserDepayTransactions from './UserDepayTransactions';
import UserCoinremitterTransactions from './UserCoinremitterTransactions';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export default function UserDashboard() {
    const formatPrice = (amount, currency)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'standard' }).format(amount);

    const params = useParams();
    const [user, setUser] = useState(null);
    const [fundingAmount, setFundingAmount] = useState(0);
    const [gateway, setGateway] = useState('');
    const [transactionId, setTransactionId] = useState('');

    const [refBal, setRefBal] = useState(0);
    const [fundingBal, setFundingBal] = useState(0);
    const [subBalance, setSubBalance] = useState(0);
    const [wthBal, setWthBal] = useState(0);

    const { isLoading: isLoadingUser, refetch: getUserAccount } = useQuery(
        "load-user",
        async () => {
          return await apiClient.get(`/api/load-user/${params.id}`);
        },
        {
          enabled: false,
          onSuccess: (res) => {
            if (res.data.status === 'success') {
                setUser(res.data.user);
                setFundingBal(res.data.funding_bal);
                setSubBalance(res.data.sub_bal);
                setRefBal(res.data.ref_bal);
                setWthBal(res.data.wth_bal);
            }
          },
          onError: (err) => {
            // console.log(err.response?.data || err);
          },
        }
      );

      const { isLoading: isFuding, mutate: postFunding } = useMutation(
        async () => {
          return await apiClient.post(`/api/fund-user/${params.id}`, {
            amount: fundingAmount,
            gateway: gateway,
            transaction_id: transactionId
          });
        },
        {
          onSuccess: (res) => {
              if (res.data.status === 'success') {
                setFundingBal(res.data.funding_bal);
                setSubBalance(res.data.sub_bal);
                setRefBal(res.data.ref_bal);
                setWthBal(res.data.wth_bal);
                

                localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
                const event = new Event('newMessage');
                window.dispatchEvent(event);
                
              }            
          },
          onError: (err) => {
                let errorMessage = err.response?.data || err ;
                localStorage.setItem('notification', JSON.stringify({message : `${errorMessage}`, 'type': 'error' }));
                const event = new Event('newMessage');
                window.dispatchEvent(event);
          },
        }
      );

      const [open, setOpen] = React.useState(false);
      const [open2, setOpen2] = React.useState(false);
      const [accountStatus, setAccountStatus] = useState('');


      const handleClickOpen = () => {
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
      const handleClose2 = () => {
        setOpen2(false);
      };

      const handleToggle2 = () => {
        setOpen2(!open2);
      };


      const { isLoading: isUpdating, mutate: updateAccount } = useMutation(
        async () => {
          if (user !== null) {
            return await apiClient.post(`/api/manage-user`, {
              user_id: params.id,
              status: accountStatus,
            });
          }
          
          
        },
        {
          onSuccess: (res) => {
              if (res.data.status === 'success') {
      
                localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
                setTimeout(() => {
                  const event = new Event('newMessage');
                  window.dispatchEvent(event);
                }, 1000);
      
                const event2 = new Event('processed');
                window.dispatchEvent(event2);                
                
              }            
          },
          onError: (err) => {
            let errorMessage = err.response?.data || err ;
            localStorage.setItem('notification', JSON.stringify({message : `${errorMessage.message}`, 'type': 'error' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            const event4 = new Event('processed');
            window.dispatchEvent(event4);
            
          },
        }
      );

      useEffect(() => {
        getUserAccount() 
        if (isLoadingUser) {
            const event3 = new Event('processing');
            window.dispatchEvent(event3);
        }
      }, []);
  return (
    <div style={{marginTop: '70px'}}>
      {isLoadingUser && 'Loading User...'}
        {user !== null &&
        <Fragment>
        <Grid container px={2} mt={4}  mb={4} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} md={6}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                    <Avatar
                        alt={user.name}
                        src={user.profile}
                        sx={{ width: 100, height: 100 }}
                        />
                        <Typography mt={2} variant="h6" gutterBottom>
                            {user.name} {user.account_status === 'active' ?<span style={{color: '#0bec4b', marginLeft: '5px'}}>({user.account_status})</span> :<span style={{color: '#ff0230', marginLeft: '5px'}}>({user.account_status})</span>}
                        </Typography>
                        <Typography variant="subtitle1" gutterBottom>
                            {user.email}
                        </Typography>
                    </Box>
                        
                    </CardContent>
                    
                </Card>
            </Grid>      
            <Grid item xs={12} md={6}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                        <List
                        subheader={<ListSubheader sx={{zIndex: 0, position: 'relative'}}>User Contact</ListSubheader>}
                        >
                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <PersonPinCircleIcon />
                                </ListItemIcon>
                                <ListItemText primary={user.address} />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <ContactPhoneIcon />
                                </ListItemIcon>
                                <ListItemText primary={user.phone} />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </CardContent>
                </Card>
            </Grid>  

            <Grid item xs={12} md={6}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                        <List
                        subheader={<ListSubheader sx={{zIndex: 0, position: 'relative'}}>Wallets Balances</ListSubheader>}
                        >
                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <AccountBalanceWalletIcon />
                                </ListItemIcon>
                                <ListItemText primary={formatPrice(fundingBal, user.currency)} secondary='Funding Balance' />
                                </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <AccountBalanceWalletIcon />
                                </ListItemIcon>
                                <ListItemText primary={formatPrice(subBalance, user.currency)} secondary='Invetment Balance' />
                                </ListItemButton>
                            </ListItem>
                            
                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <AccountBalanceWalletIcon />
                                </ListItemIcon>
                                <ListItemText primary={formatPrice(refBal, user.currency)} secondary='Referral Balance' />
                                </ListItemButton>
                            </ListItem>

                            <ListItem disablePadding>
                                <ListItemButton>
                                <ListItemIcon>
                                    <AccountBalanceWalletIcon />
                                </ListItemIcon>
                                <ListItemText primary={formatPrice(wthBal, user.currency)} secondary='Withdrawable Balance' />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </CardContent>
                    <CardActions sx={{justifyContent: 'center'}}>
                    
                    <Button onClick={handleClickOpen} variant="outlined" size='large' color="primary">
                        {isFuding? 'Funding Wallet...' : 'Fund Wallet'}
                    </Button>

                    <Button onClick={()=> handleToggle2()} variant="outlined" size='large' color="secondary">
                        {isUpdating? 'Updating User...' : 'Manage User'}
                    </Button>

                    </CardActions>
                </Card>
            </Grid> 
    
            {user.roles.length > 0 &&
            <Grid item xs={12} md={6}>
            <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                        <List
                            sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
                            subheader={<ListSubheader>Account Roles</ListSubheader>}
                            >
                           {user.roles.map(role =>(
                                <ListItem key={role.id}>
                                <ListItemIcon>
                                <AdminPanelSettingsIcon/>
                                </ListItemIcon>
                                <ListItemText id={role.role} primary={`${role.role}`} />
                            </ListItem>
                            ))}                        
                        </List>
                    </CardContent>
                </Card>
            </Grid> 
            }  

            <Grid item xs={12} md={12}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                    <UserPlates investments={user.investments}/>
                    </CardContent>
                </Card>
            </Grid>   

             <Grid item xs={12} md={12}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                    <UserWithdrawals withdrawals={user.withdrawals}/>
                    </CardContent>
                </Card>
            </Grid> 
            
            {/* <Grid item xs={12} md={12}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                    <UserDepayTransactions transactions={user.depaytransactions}/>
                    </CardContent>
                </Card>
            </Grid>  */}

            {/* <Grid item xs={12} md={12}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                    <UserCoinremitterTransactions transactions={user.wallets}/>
                    </CardContent>
                </Card>
            </Grid> */}
        </Grid>
        </Fragment>
        }
       
       
      
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{textAlign: 'center'}}>{`FUND USER WALLET WITH ANY AMOUNT IN ${user && user.currency}`}</DialogTitle>

        <DialogContent>

          <DialogContentText sx={{textAlign: 'center'}} mb={2} id="alert-dialog-slide-description">
            
            Kindly enter any amount to fund in {user && user.currency}
          </DialogContentText>

          <Box px={1}>
            <FormControl  sx={{ m: 1, minWidth: '100%' }} size="small">
              <InputLabel htmlFor="amount">Enter Amount To Fund</InputLabel>
              <OutlinedInput
                onChange={(e)=>setFundingAmount(e.target.value)}
                id="amount"
                type={'number'}
                label="Enter Amount To Fund"
              />
            </FormControl>

            <FormControl  sx={{ m: 1, minWidth: '100%' }} size="small">
              <InputLabel htmlFor="transactionId">Transaction ID</InputLabel>
              <OutlinedInput
                onChange={(e)=>setTransactionId(e.target.value)}
                id="transactionId"
                type={'text'}
                label="Transaction ID"
              />
            </FormControl>


            <FormControl  sx={{ m: 1, minWidth: '100%' }} size="small">
              <InputLabel htmlFor="gateway">Gateway</InputLabel>
              <Select
                  onChange={(e)=>setGateway(e.target.value)}
                  labelId="gateway"
                  name="gateway"
                  id="gateway"
                  label={`Gateway`}
                  >
                  <MenuItem selected value=''>
                  Select Gateway
                  </MenuItem> 

                  <MenuItem selected value="Withdrawable Balance">
                  Withdrawable Balance
                  </MenuItem>

                  <MenuItem selected value="Funding Wallet">
                  Funding Wallet
                  </MenuItem>

                  <MenuItem selected value="Referral Wallet">
                  Referral Wallet
                  </MenuItem> 
              </Select>                        
          </FormControl>

            {/* <FormControl  sx={{ m: 1, minWidth: '100%' }} size="small">
              <InputLabel htmlFor="gateway">Gateway</InputLabel>
              <OutlinedInput
                onChange={(e)=>setGateway(e.target.value)}
                id="gateway"
                type={'text'}
                label="Gateway"
              />
            </FormControl> */}
          </Box>          
        </DialogContent>
        
        <DialogActions>
        <Button onClick={postFunding}  color='success'>{isFuding? 'funding...': 'fund account'}
        </Button>
        </DialogActions>
      </Dialog>



      <Dialog
        
        open={open2}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose2}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{textAlign: 'center'}}>{"UPDATE ACCOUNT STATUS"}</DialogTitle>
        <DialogContent>
          <Box px={1} sx={{display: 'flex', justifyContent: 'space-evenly'}}>

          <FormControl fullWidth={true} sx={{ m: 1, minWidth: 50 }}>
            <InputLabel htmlFor="status">status</InputLabel>
                <Select
                    onChange={(e) => setAccountStatus(e.target.value)}
                    labelId="status"
                    id="status"
                    name="status"
                    label='status'
                    >
                    <MenuItem selected value=''>
                        select status
                    </MenuItem> 
                    <MenuItem value="active">active</MenuItem>
                    <MenuItem value="inactive">inactive</MenuItem>
                    <MenuItem value="delete">delete account</MenuItem>
                </Select>
            </FormControl>
          </Box>          
        </DialogContent>
        
        <DialogActions>
            <Button onClick={()=>updateAccount()} color='error'>{isUpdating? 'Updating...': 'Update Status'}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}