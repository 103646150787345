import React, {useEffect} from 'react'
import CoinMarket from './CoinMarket';
import WalletCards from '../Wallet/WalletCards';

const Index = () => {

  useEffect(() => {
    document.title = 'DASHBOARD';
});
  return (
    <>
    {/* <BalanceCard/> */}
    
    {/* <PyramidData/> */}
    {/* <MobileMenu/> */}
    
    <CoinMarket/>
    <WalletCards/>
    </>
  )
}

export default Index
