import React, {useState, useEffect} from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import WalletSlide from "../Wallet/WalletSlide";
import { QRCode } from 'react-qrcode-logo';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import PendingIcon from '@mui/icons-material/Pending';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const Referrals = () => {
    const user = useSelector((state) => state.user.user);
    const referrals = useSelector((state) => state.referrals.referrals);
    const [buttonText, setbuttonText] = useState(null);
    const refLink = window.location.origin + '/invitation?referral='+encodeURIComponent(user.referral_code);

    useEffect(() => {
        document.title = 'REFERRALS';
    });
  return (
    <div>
        <WalletSlide/>
        <Container>
            <Typography mb={1}  ml={3} mt={4} sx={{ fontWeight: 'bold', fontSize: 20, textTransform: 'uppercase', textAlign: 'center'  }} gutterBottom variant="h6" component="div">
                Referrals
            </Typography>
            <Typography  sx={{textAlign: 'center'}} variant="body2" color="text.secondary">
                use this provided link below to invite family and friends to join your network
            </Typography>

            <Grid container px={2} mt={4}  mb={4} rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12} md={5}>
                    <Card px={2}  sx={{bgcolor:'#fff', borderRadius: '9px', height: '100%'}}>
                    
                    <QRCode qrStyle="dots" value={refLink} size={200} logoImage='https://res.cloudinary.com/dzxyvdq14/image/upload/v1689553552/globalwealthshare-appicon.png' />

                    <CardActions sx={{justifyContent: 'center'}}>
                        <CopyToClipboard onCopy={() => {setbuttonText('referral code copied!');
                            setTimeout(() => {
                                setbuttonText(null);
                            }, 2000);}} text={refLink}>
                                <Button variant='contained'>
                                    {buttonText !== null? buttonText: 'Copy Referral Code'}
                                </Button>
                        </CopyToClipboard>
                    </CardActions>
                    </Card>
                </Grid>

                <Grid item xs={12} md={7}>
                    {referrals.length < 1 &&
                        <Box mx={2} mt={10}>
                        <Stack sx={{ width: '100%' }} spacing={2}>
                            <Alert severity="info">You have not invited anyone yet!</Alert>
                        </Stack>
                        </Box>
                    }


                    {referrals.length > 0 &&
                    <Card sx={{borderRadius: '9px', height: '100%', paddingX: '10px', paddingY: '10px'}}>
                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                            {referrals.map(referral => (
                                <ListItem key={referral.id}>
                                    <ListItemAvatar>
                                        <Avatar src={referral.invited.profile}/>
                                    </ListItemAvatar>
                                    <ListItemText primary={referral.invited.name} secondary={`From ${referral.invited.country}`} />
                                    <IconButton aria-label="comment">
                                        {referral.status === 'pending'? <PendingIcon color='warning'/> : <CheckCircleOutlineIcon color='success'/> }
                                    </IconButton>
                                </ListItem>
                            ))}
                        </List>
                    </Card>
                    }
                </Grid>
            </Grid>
        </Container>
    </div>
  )
}

export default Referrals
