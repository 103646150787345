import React, {useState, useEffect, Fragment} from 'react';
import './App.css';
import Header from './layouts/Header';
import SideBar from './layouts/SideBar';
import Footer from './layouts/Footer';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import SideBarDesktop from './layouts/SideBarDesktop';
import { Routes, Route } from "react-router-dom";
import Index from './components/Home/Index';
import Grid from '@mui/material/Grid';
import Wallet from './components/Wallet/Wallet';
import {
  QueryClient,
  QueryClientProvider,
} from 'react-query';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Register from './components/Auth/Register';
import Login from './components/Auth/Login';
import { useSelector } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import MissingRoute from './components/MissingRoute';
import ListNotifications from './components/Notifications/ListNotifications';
import FetchNotifications from './request/Notifications/FetchNotifications';
import Profile from './components/Settings/Profile';

// admin routes
import UploadProducts from './components/Admin/UploadProducts';
import ListPlates from './components/Admin/ListPlates';
import CreatePlans from './components/Admin/CreatePlans';
import PushNotification from './components/Settings/PushNotification';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setRedirect } from './components/Auth/historySlice';
import Referrals from './components/Referrals/Referrals';
import AdminMail from './components/Admin/Mail/AdminMail';
import UserDashboard from './components/Admin/User/UserDashboard';
import ListTransfer from './components/Admin/User/ListTranfers';
import Forgot from './components/Auth/Forgot';
import UpdatePassword from './components/Auth/UpdatePassword';
import AdminSms from './components/Admin/Mail/AdminSms';
// import FetchAnalysis from './request/Analysis/FetchAnalysis';
// import AdminAnalysis from './components/Admin/AdminAnalysis';
// import ChartAnalysis from './request/Analysis/ChartAnalysis';
import LoadFundings from './request/Funding/LoadFundings';
import LoadPlans from './request/Plans/LoadPlans';
import InvestmentPlans from './components/plans/InvestmentPlans';
import FetchCoinRates from './request/CoinRates/FetchConRates';
import AccountHistory from './request/Analysis/AccountHistory';
import Withdrawals from './components/Withdrawal/Withdrawals';
import Investment from './components/Investment/Investment';
import Transactions from './components/Transactions/Transactions';
import Invitation from './components/Home/Invitation';
import LoadReferrals from './request/Referrals/LoadReferrals';
import ListUsers from './components/Admin/ListUsers';
import ListPlans from './components/Admin/ListPlans';
import EditPlan from './components/Admin/EditPlan';
import ListInvestments from './components/Admin/ListInvestments';
import DepayTransactions from './components/Admin/DepayTransactions';
import CoinremitterTransactions from './components/Admin/CoinremitterTransactions';
import WithdrawalList from './components/Admin/WithdrawalList';
import CreateAddress from './components/Admin/CreateAddress';
import ListAddresses from './components/Admin/ListAddresses';
import Editwallet from './components/Admin/EditAddress';
import ListFundings from './components/Admin/ListFundings';
import GetRates from './request/Earning/GetRates';
import CheckAuth from './request/Auth/CheckAuth';
import Cards from './components/Cards/Cards';
import Shareholders from './components/Shareholders/Shareholders';
import Loans from './components/Loans/Loans';
import ListCards from './components/Admin/ListCards';
import ListShareholders from './components/Admin/ListShareholders';
import ListLoans from './components/Admin/ListLoans';
import MarketsAccount from './components/Markets/MarketsAccounts';
import JobApplication from './components/Job/JobApplication';
import AdminJobApplications from './components/Admin/AdminJobApplications';
import ListReferral from './components/Admin/ListReferrals';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

const queryClient = new QueryClient();

function App() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open2, setOpen2] = React.useState(false);
  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleToggle2 = () => {
    setOpen2(!open2);
  };

  const user = useSelector((state) => state.user.user);
  const mode = useSelector((state) => state.mode.mode);
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const userRole = useSelector((state) => state.user.currentRole);

  
  const [message, setMessage] = useState(null);

  const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(true);
      };
    const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setOpen(false);
  };
  
  const darkTheme = createTheme({
    palette: {
      mode: mode,
      primary: {
        success: '#00923f',
        // light: will be calculated from palette.primary.main,
        main: '#00913f',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      custom: {
        main: '#fff'
      },
      secondary: {
        // light: '#0066ff',
        main: '#d4251e',
        // dark: will be calculated from palette.secondary.main,
        // contrastText: '#ffcc00',
      },
  
    },
    typography: {
      fontFamily: ['Fira Sans', ' sans-serif'].join(','),
    },
  });

  useEffect(() => {
   
    window.addEventListener('newMessage', function (e) {
      const notification = JSON.parse(localStorage.getItem('notification'));
      if (notification) {
        
        if (notification.message === 'Unauthenticated.') {
          let pathName = window.location.pathname;
          dispatch(setRedirect(pathName));
          setMessage(notification);
          handleClick();
          setTimeout(() => {
            navigate('/login');
          }, 1000);
          
        }else{
          setMessage(notification);
          handleClick();
  
        }
      }
  }, false); 

  window.addEventListener('processing', function (e) {
    handleToggle2();
}, false); 
window.addEventListener('processed', function (e) {
  handleClose2();
}, false);

  }, [user]);

  if (isAuth) {
    return (
      <div className="App">
        <QueryClientProvider client={queryClient}>
          <CheckAuth/>
          <FetchCoinRates/>
          <FetchNotifications/>   
          <LoadFundings/> 
          <LoadPlans/>  
          <AccountHistory/>
          <LoadReferrals/>
          <GetRates/>
        <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Header/>
        <SideBar/>
          <Grid container spacing={0}>
            <Grid sx={{display: {md: 'flex', sm: 'none', xs: 'none'}}} item md={2}>
            <SideBarDesktop/>
            </Grid>
            <Grid item xs={12} md={10}>
              <Routes>   
                {userRole === 'admin' ? <Route path='/' element={<Index/>} /> : <Route path='/' element={<Index/>} /> }
                
                <Route path='/plans' element={<InvestmentPlans/>} />
                <Route path='/wallet' element={<Wallet/>} />
                <Route path='/notifications' element={<ListNotifications/>} />
                <Route path='/profile' element={<Profile/>} />
                <Route path='/referrals' element={<Referrals/>} />
                <Route path='/push-notification' element={<PushNotification/>} />              
                <Route path='/withdrawals' element={<Withdrawals/>} />              
                <Route path='/investments' element={<Investment/>} />              
                <Route path='/transactions' element={<Transactions/>} />      
                <Route path='/cards' element={<Cards/>} />      
                <Route path='/shareholders' element={<Shareholders/>} />      
                <Route path='/loans' element={<Loans/>} />      
                <Route path='/markets' element={<MarketsAccount/>} />      
                <Route path='/job-application' element={<JobApplication/>} />      
                <Route path='/invitation' element={<Invitation/>} />

                
                {/* admin routes */}

                {userRole === 'admin' &&
                <Fragment>
                  
                  <Route path='/list-loans' element={<ListLoans/>} />     
                  <Route path='/list-cards' element={<ListCards/>} />     
                  <Route path='/list-shareholders' element={<ListShareholders/>} />     
                  <Route path='/list-investments' element={<ListInvestments/>} />
                  <Route path='/depay-transactions' element={<DepayTransactions/>} />
                  <Route path='/coinremitter-transactions' element={<CoinremitterTransactions/>} />
                  <Route path='/list-withdrawals' element={<WithdrawalList/>} />
                  <Route path='/list-job-applications' element={<AdminJobApplications/>} />
                  
                  <Route path='/list-referrals' element={<ListReferral/>} />
                  <Route path='/list-users' element={<ListUsers/>} />
                  <Route path='/list-plans' element={<ListPlans/>} />
                  <Route path='/list-fundings' element={<ListFundings/>} />
                  <Route path='/edit-plan/:id' element={<EditPlan/>} />  
                  <Route path='/upload' element={<UploadProducts/>} />
                  <Route path='/send-mail' element={<AdminMail/>} />
                  <Route path='/send-sms' element={<AdminSms/>} />
                  {/* <Route path='/admin-analysis' element={<AdminAnalysis/>} /> */}
                  <Route path='/create-plans' element={<CreatePlans/>} />
                  <Route path='/create-address' element={<CreateAddress/>} />
                  <Route path='/edit-address/:id' element={<Editwallet/>} />  

                  <Route path='/list-address' element={<ListAddresses/>} />
                <Route path='/user-dashboard/:id' element={<UserDashboard/>} />             
                           
                </Fragment>
                }


                {userRole === 'editor' &&
                <Fragment>
                <Route path='/list-orders' element={<ListPlates/>} />
                <Route path='/upload' element={<UploadProducts/>} />
                <Route path='/list-transfers' element={<ListTransfer/>} /> 
                           
                </Fragment>
                }
                <Route path="*" element={<MissingRoute/>} />
              </Routes>
            </Grid>
          </Grid>
        <Footer/>
        {message !== null &&
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={message.type} sx={{ width: '100%' }}>
            {message.message}
          </Alert>
        </Snackbar>
        }
        {/* {userRole === 'admin' && 
          <Fragment>
            <FetchAnalysis/>
            <ChartAnalysis/>
          </Fragment>        
        } */}
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open2}
          >
          <CircularProgress color="inherit" />
      </Backdrop>
        </ThemeProvider>
        </QueryClientProvider>
      </div>
    );
  }else{
    return (
      <div className="App">
        <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <CheckAuth/>
        {/* <Header/>
        <SideBar/> */}
          <Grid container spacing={0}>
            <Grid sx={{display: {md: 'flex', sm: 'none', xs: 'none'}}} item md={2}>
            <SideBarDesktop/>
            </Grid>
            <Grid item xs={12} md={10}>
              <Routes>   
                <Route path='/' element={<Login/>} />
                {/* <Route path='/wallet' element={<Wallet/>} /> */}
                <Route path='/notifications' element={<ListNotifications/>} />
                <Route path='/push-notification' element={<PushNotification/>} />             
                {/* <Route path='/' element={<Index/>} /> */}
                <Route path='/register' element={<Register/>} />
                <Route path='/login' element={<Login/>} />
                <Route path='/forgot' element={<Forgot/>} />
                <Route path='/invitation' element={<Invitation/>} />
                <Route path='/update-password' element={<UpdatePassword/>} />
                
                {/* admin routes */}              
                <Route path="*" element={<MissingRoute/>} />
              </Routes>
            </Grid>
          </Grid>
        {message !== null &&
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={message.type} sx={{ width: '100%' }}>
            {message.message}
          </Alert>
        </Snackbar>
        }
        
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open2}
          >
          <CircularProgress color="inherit" />
      </Backdrop>

        </ThemeProvider>
        </QueryClientProvider>
      </div>
    )
  }
  
}

export default App;
